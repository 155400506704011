export const TOKEN_KEY = 'token';
export const infoEmailAddress = 'info@presto-assistant.com';
export const supportEmailAddress = 'support@presto-assistant.com';
export const supportSiteUrl = 'https://support.presto-assistant.com';

// Mailto Links
export const mailtoInfo = `mailto:${infoEmailAddress}?subject=From%20the%20Presto%20website`;
export const mailtoInfoFromContactPage = `mailto:${infoEmailAddress}?subject=From%20the%20Presto%20website%20Contact%20Page`;
export const mailtoSupportFromContactPage = `mailto:${supportEmailAddress}?subject=From%20the%20Presto%20website%20Contact%20Page`;

// Telephone Numbers
export const mainPhoneNumber = '(972) 640-1010';
export const mainPhoneNumberLink = 'tel:+19726401010';

// Social Media Links
export const FACEBOOK_LINK = 'https://www.facebook.com/Presto-Assistant-1058031084381957';
export const INSTAGRAM_LINK = 'https://www.instagram.com/prestoassistant/';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/presto-assistant/';
export const TWITTER_LINK = 'https://twitter.com/PrestoAssistant';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UCgdcIp467KUKmHORFy1_SUg';
